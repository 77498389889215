import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import sun from "../assets/images/sun_bg.png";

const Loader = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {}, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full h-screen flex justify-center items-center"
      >
        <div className="relative flex w-full h-screen justify-center items-center">
          <img
            src={sun}
            alt="Аскерова Марина - Таролог и энергопрактик"
            width={500}
            className=" animate-spin-slow"
          />
          <p className="absolute text-center font-Philosopher leading-6 text-xl text-accentColor2">
            Загружаем <br /> страницу...
          </p>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Loader;
