import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import Privacy from "./pages/Privacy";
import Oferta from "./pages/Oferta";
import ScrollToTop from "./components/ScrollToTop";
import { ModalContainer } from "reoverlay";

// pages
const Home = lazy(() => import("./pages/Home"));

function App() {
  return (
    <BrowserRouter>
      <main>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/oferta" element={<Oferta />} />
          </Routes>
        </ScrollToTop>
      </main>
      <ModalContainer />
    </BrowserRouter>
  );
}

export default App;
