import logo from "../assets/images/taro_sun.webp";

function Button({ children }) {
  return (
    <button className="text-sm bg-accentColor3 px-6 py-2 rounded-3xl my-6">
      {children}
    </button>
  );
}

const Oferta = () => {
  return (
    <section className="max-w-7xl mx-auto px-6">
      {/* logotype */}
      <div className="flex items-center justify-center py-10 ">
        <img
          src={logo}
          alt=""
          className="w-full h-full max-w-[60px] xl:max-w-[100px]"
        />
        <div className="flex flex-col md:hidden lg:flex">
          <span className="text-2xl xl:text-4xl font-bold text-accentColor2 font-Philosopher leading-none">
            Аскерова
          </span>
          <span className="-mt-2 text-2xl xl:text-4xl font-bold text-accentColor2 font-Philosopher leading-none">
            Марина
          </span>
        </div>
      </div>
      <a href="/" className="">
        <Button>Вернуться на главную</Button>
      </a>
      <div className="space-y-2 text-sm lg:text-base">
        <p className="text-base lg:text-xl text-center font-bold">
          {" "}
          Договор-оферта
        </p>
        <p className="font-bold">1. Общие положения</p>
        <p>
          1.1. Данный документ является публичной офертой Аскеровой Марины
          Федоровны, в дальнейшем именуемого «Исполнитель», и содержит все
          существенные условия договора на оказание
          информационно-консультационных услуг (в соответствии со статьей 435 и
          частью 2 статьи 437 Гражданского кодекса РФ).
        </p>
        <p>
          1.2. Лицо, осуществившее акцепт настоящей публичной оферты,
          приобретает все права и обязанности Заказчика, предусмотренные
          настоящим договором.
        </p>
        <p>
          1.3. Акцептом настоящей публичной оферты является осуществление
          Заказчиком полной или частичной оплаты информационно-консультационных
          услуг в соответствии с условиями настоящего договора. С момента
          поступления денежных средств в счет оплаты оказываемых услуг на
          расчетный счет Исполнителя, настоящий договор считается заключенным
          между Заказчиком и Исполнителем.
        </p>
        <p>
          1.4. Принятие настоящей Оферты и соответственно, заключение настоящего
          Договора означает, что Заказчик в необходимой для него степени
          ознакомился с условиями настоящего договора и правилами платежной
          системы (далее — Системы), спецификой функционирования Системы и
          сайта, на котором размещаются обучающие материалы, признает
          безусловную пригодность Системы, сайта для совершения действий и
          достижения целей, являющихся предметом настоящего договора.
        </p>
        <p>
          1.5. Осуществляя акцепт настоящей оферты, Заказчик подтверждает, что
          оказание Исполнителем услуг по настоящему договору дистанционно с
          использованием программного обеспечения (далее — ПО) полностью
          соответствует возможности Заказчика пользоваться услугами,
          оказываемыми таким способом.
        </p>
        <p>
          1.6. Актуальный договор-оферта всегда находится по адресу:
          https://askerova-marina.ru/oferta.
        </p>
        <p>
          1.7. В настоящем договоре, если из его текста прямо не вытекает иное,
          следующие слова и выражения будут иметь указанные ниже значения:
        </p>
        <p>
          1.7.1. Договор – настоящий документ, опубликованный в сети Интернет,
          направляемый в целях ознакомления по запросу посредством электронной
          почты либо предоставляемый любыми другими способами.
        </p>
        <p>1.7.2. Заказчик – получатель услуг по настоящему договору.</p>
        <p>
          1.7.3. Услуга – предоставляемые на возмездной основе организуемые
          информационно-консультационные занятия с обратной связью (и/или
          предоставление их записей), направленные на передачу Заказчику знаний,
          умений и навыков по вопросам эзотерической направленности.
        </p>
        <p>
          1.7.4. Программное обеспечение (ПО) – браузер (Internet Explorer,
          FireFox, Google Chrome и аналогичные) для доступа к информационным
          ресурсам, находящихся в сети Интернет, иные программы для передачи,
          хранения, обработки предоставляемой информации. Заказчик обязуется
          самостоятельно обеспечить наличие ПО на своем персональном компьютере.
        </p>
        <p>
          1.7.5. Обратная связь – информационно-консультационная услуга в форме
          проведения устной и/или письменной консультации и ответов на вопросы в
          виде вебинара с определенной тематикой посредством сети Интернет.
        </p>
        <p>
          1.7.6. Вебинар – это: — запись видеопрезентации обучающего курса, с
          комментариями Исполнителя; — трансляция выступления Исполнителя в
          режиме реального времени. Обратная связь между Исполнителем и
          Заказчиком по вопросам темы вебинара осуществляется с использованием
          чата или на определенном интернет сайте.
        </p>
        <p>
          1.7.8. Сайт — совокупность информации, текстов, графических элементов,
          дизайна, изображений, фото и видеоматериалов и иных результатов
          интеллектуальной деятельности, а также программ для ЭВМ, содержащихся
          в информационной системе, обеспечивающей доступность такой информации
          в сети Интернет по сетевому адресу https://askerova-marina.ru/.
        </p>
        <p className="font-bold">2. Предмет договора-оферты</p>
        <p>
          2.1. Предметом настоящей оферты является возмездное оказание Заказчику
          информационно-консультационных услуг эзотерической направленности.
        </p>
        <p>
          2.2. Оказание услуг осуществляется путем размещения Исполнителем на
          интернет ресурсах и в закрытых группах социальных сетей обучающего
          материала и заданий для Заказчика, направленных на передачу
          необходимых знаний и навыков, согласно графику, установленному
          Исполнителем, письменных и устных ответов на вопросы Заказчика, а
          также иной информационной поддержки Заказчика при прохождении
          обучающей программы.
        </p>
        <p>
          Услуги по настоящему договору ограничены предоставлением Заказчику
          информации и формированием навыков для ее самостоятельного
          использования.
        </p>
        <p>
          2.3. Услуги по настоящему договору оказываются Исполнителем
          дистанционно через сеть Интернет, посредством программного обеспечения
          (ПО).
        </p>
        <p className="font-bold">
          3. Сроки оказания услуг и порядок их переноса
        </p>
        <p>
          3.1. Срок оказания услуг по настоящему договору соответствует сроку
          программы обучения по выбранному курсу, указанному на Сайте.
        </p>
        <p>
          3.2. График и содержание обучающей программы размещаются на Сайте.
        </p>
        <p>
          3.3. Услуги по настоящему договору считаются оказанными с надлежащим
          качеством и в срок, а также принятыми Заказчиком, если в течение 3
          (трех) календарных дней по истечению срока, указанного в п. 3.1
          настоящего договора, Заказчик не заявил мотивированного возражения на
          качество и объем таких услуг путем отправки соответствующей обращения
          по адресу am-taro@gmail.com.
        </p>
        <p>
          3.4. Доступ к обучающим материалам сохраняется за Заказчиком в течение
          1 (одного) календарного месяца после завершения обучающей программы в
          сроки, установленные п. 3.1. настоящего договора.
        </p>
        <p className="font-bold">4. Права и обязанности сторон</p>
        <p> 4.1. Исполнитель обязуется:</p>
        <p>
          4.1.1. сохранять конфиденциальную информацию, полученную от Заказчика,
          при оказании информационно-консультационных услуг по настоящему
          договору.
        </p>
        <p>
          4.1.2. соблюдать требования законодательства, касающиеся обработки,
          передачи и защиты персональных данных Заказчика.
        </p>
        <p> 4.2. Исполнитель вправе:</p>
        <p>
          4.2.1. в одностороннем порядке изменять и дополнять условия настоящего
          договора, без предварительного согласования с Заказчиком, обеспечивая
          при этом публикацию измененных условий на сайте
          https://askerova-marina.ru/, не менее чем за один день до их введения
          в действие.
        </p>
        <p>
          4.2.2. в одностороннем порядке расторгнуть настоящий договор в случае
          существенного нарушения Заказчиком условий настоящего Договора. При
          этом денежные средства, оплаченные Заказчиком по настоящему договору,
          возврату не подлежат и являются штрафной неустойкой за действия
          Заказчика.
        </p>
        <p>
          Под существенным нарушением условий настоящего Договора понимается
          любое нарушение авторских прав, регламентированных действующим
          законодательством РФ “Об авторском праве”, в том числе однократное
          нарушение Заказчиком п. 4.2.3.настоящего договора.
        </p>
        <p> 4.3. Заказчик обязуется:</p>
        <p>
          4.3.1. для получения услуг по настоящему договору Заказчик должен
          иметь персональный компьютер с доступом в сеть Интернет с
          установленным ПО.
        </p>
        <p>
          4.3.2. после выбора услуги, принятия настоящей публичной оферты и
          оплаты в полном объеме или частично информационно-консультационных
          услуг придерживаться установленного графика обучающей программы, целей
          и сути выполнения заданий{" "}
        </p>
        <p>
          Исполнителя, выполнять рекомендации и требования Исполнителя в рамках
          оказания услуг по настоящему договору.
        </p>
        <p>
          4.3.3. предоставить Исполнителю актуальную информацию, необходимую для
          направления Заказчику информационных материалов, а также для
          оперативной связи с Заказчиком в рамках оказания услуг по настоящему
          договору.
        </p>
        <p>
          4.3.4. соблюдать правила поведения на занятиях и консультациях,
          проявлять уважение к Исполнителю, другим участникам обучающей
          программы.
        </p>
        <p>
          4.3.5. не распространять (публиковать, размещать на Интернет-сайтах,
          копировать, передавать или перепродавать третьим лицам) в коммерческих
          или некоммерческих целях предоставляемую Исполнителем Заказчику
          информацию и материалы в рамках настоящего договора, создавать на ее
          основе информационные продукты с целью извлечения коммерческой
          прибыли, а также использовать эту информацию каким-либо иным образом,
          кроме как для личного пользования.
        </p>
        <p>
          4.3.6. не размещать на Сайте и в групповых чатах персональные данные
          третьих лиц без их согласия, в том числе домашние адреса, телефоны,
          паспортные данные, адреса электронной почты.
        </p>
        <p>
          4.3.7. не размещать на Сайте и в групповых чатах коммерческую рекламу,
          коммерческие предложения, агитационную информацию и любую другую
          навязчивую информацию, кроме случаев, когда размещение такой
          информации согласовано с Исполнителем.
        </p>
        <p> 4.4. Заказчик имеет право:</p>
        <p>
          4.4.1. в любой момент отказаться от продолжения изучения курса и не
          вносить оплаты за следующие модули. Оплата за модули, учебный материал
          по которым уже получен, не возвращается.
        </p>
        <p>
          4.4.2. обратиться к Исполнителю с письменным заявлением о переносе
          сроков оказания оплаченных услуг по настоящему договору.
        </p>
        <p className="font-bold">5. Стоимость услуг и порядок оплаты</p>
        <p>
          5.1. Стоимость информационно-консультационных услуг по настоящему
          договору указывается на Сайте по адресу: https://askerova-marina.ru/ и
          может быть изменена Исполнителем в любое время в одностороннем
          порядке. Новая стоимость вступает в силу с момента опубликования и не
          распространяется на оплаченные к моменту опубликования услуги.
        </p>
        <p>
          5.2. Оплата выбранной услуги производится Заказчиком путем зачисления
          денежных средств в порядке предоплаты за каждый модуль обучения или
          единоразово за весь курс/консультацию на расчетный счет Исполнителя.
          Заказчик, не внесший предоплату до начала очередного модуля из группы
          исключается.
        </p>
        <p>
          5.3. Моментом оплаты считается поступление денежных средств на
          расчетный счет Исполнителя.
        </p>
        <p className="font-bold">
          6. Условия и порядок возврата денежных средств
        </p>
        <p>
          6.1. Возврат денежных средств за не проведенную консультацию
          осуществляется по письменному заявлению Заказчика, направленного на
          электронный адрес: am-taro@gmail.com в течение 5 (пяти) рабочих дней,
          за исключением следующих случаев:
        </p>
        <p>
          6.1.1. В процессе обучения у Заказчика возникли проблемы личного
          характера (проблемы со здоровьем, смена жизненных интересов и
          приоритетов и другие подобные проблемы), по которым Исполнитель не
          может нести ответственность, и Заказчик не обращался с заявлением о
          переносе сроков обучения по курсу.
        </p>
        <p>
          6.1.2. Заказчик не запрашивал помощь у Исполнителя для решения
          возникших в процессе обучения вопросов.
        </p>
        <p>
          6.2. Возврат денежных средств осуществляется при отсутствии оснований
          для отказа в возврате, установленных п. 6.1. настоящего договора, за
          вычетом фактических затрат Исполнителя на момент возврата. К таким
          фактическим затратам Исполнителя относятся (но не ограничиваются) в
          частности комиссии банковских, кредитных организаций и соответствующих
          платежных систем за осуществление возврата денежных средств, а также
          стоимость бонусных материалов, предоставленных Заказчику. Кроме того,
          к фактическим затратам относятся стоимость предоставленных Заказчику
          обучающих материалов, организованных Исполнителем Занятий и иных
          онлайн-встреч, даже если Заказчик не использовал материалы и не
          участвовал в Занятиях без уважительных причин, подтвержденных
          документально.
        </p>
        <p>
          6.3. Решение о возврате или об отказе в возврате денежных средств
          принимается Исполнителем в течение 3 (трех) рабочих дней с момента
          получения соответствующего заявления Заказчика.
        </p>
        <p>
          6.4. Денежные средства возвращаются на счет Заказчика, с которого
          производилась оплата обучения, либо иной счет, указанный Заказчиком в
          заявлении о возврате, в течение 5 (пяти) рабочих дней после принятия
          решения о возврате.
        </p>
        <p>
          6.5. Требование о возврате оплаченных сумм Исполнителем
          рассматривается, если Заказчик предоставит письменные доказательства
          того, что соответствующая услуга, входящая в приобретенный пакет
          услуг, не была оказана (оказана ненадлежащим образом) по вине
          Исполнителя.
        </p>
        <p>
          В случае установления факта неоказания услуг (оказании услуг
          ненадлежащего качества) по соответствующей опции приобретенных услуг
          Исполнитель осуществляет возврат денег за минусом фактических затрат
          Исполнителя, определяемых на момент возврата по правилам п. 6.2.
          настоящего договора.
        </p>
        <p>
          6.6. Исполнитель оставляет за собой право отклонить заявку Заказчика
          на возврат денежных средств в случае появления со стороны Заказчика
          неуважительного отношения к Исполнителю или иным участникам обучающих
          курсов.
        </p>
        <p>
          6.7. В случае удовлетворения заявления о возврате доступ к обучающим
          материалам для Заказчика прекращается в течение 1 (одного) рабочего
          дня с даты направления Исполнителем Заказчику решения о
          соответствующем возврате. Указанное требование Заказчика о возврате
          также считается отзывом заранее данного акцепта, указанного в пункте
          1.2 настоящего договора.
        </p>
        <p>
          6.8. Заявление о возврате денежных средств, направленное Исполнителю
          по истечении 3 (трех) календарных дней после завершения обучающей
          программы не рассматриваются.
        </p>
        <p className="font-bold">7. Ответственность </p>
        <p>
          7.1. Исполнитель не несет ответственности за невозможность оказания
          услуг Заказчику по причинам, не зависящим от Исполнителя, а именно:
          нарушение работы Интернета, оборудования или ПО со стороны Заказчика.
          В данном случае услуги считаются оказанными надлежащим образом и
          подлежащим оплате в полном размере.
        </p>
        <p>
          7.2. Никакая информация, материалы и/или консультации, предоставляемые
          Исполнителем в рамках оказания услуг по настоящему договору, не могут
          рассматриваться как гарантии. Принятие решений на основе всей
          предоставленной Исполнителем информации находится в исключительной
          компетенции Заказчика. Заказчик принимает на себя полную
          ответственность и риски, связанные с использованием информации и
          материалов, предоставленных Исполнителем в рамках исполнения своих
          обязательств по настоящему Договору.
        </p>
        <p>
          7.3. Исполнителем не гарантируется абсолютная бесперебойность
          предоставления услуг по настоящему договору, несмотря на то, что
          Исполнитель предпринимает все возможные меры с целью недопущения
          вышеперечисленного. В случае неудовлетворительного качества
          интернет-соединения, стабильная работа ПО не гарантируется, в этом
          случае получение услуг по настоящему договору может оказаться
          затруднительным или невозможным.
        </p>
        <p>
          7.4. Стороны освобождаются от ответственности за неисполнение или
          ненадлежащее исполнение обязательств по настоящему договору на время
          действия непреодолимой силы. В течение этого времени стороны не имеют
          взаимных претензий, и каждая из сторон принимает на себя свой риск
          последствия форс–мажорных обстоятельств. О возникновении таких
          обстоятельств Исполнитель обязан уведомить Заказчика путем размещения
          информации на Сайте и/или в групповом чате, а Заказчик обязан
          направить Исполнителю письмо на электронную почту am-taro@gmail.com с
          указанием в строке Тема «Форс-мажор”.
        </p>
        <p>
          Под обстоятельствами непреодолимой силы (форс-мажорными
          обстоятельствами) Стороны понимают: пожар, наводнение, землетрясение,
          забастовки и другие стихийные бедствия, война и военные действия,
          вступление в силу нормативных правовых актов и актов применения права,
          препятствующих исполнению обязательств, вынужденная срочная (не
          плановая) госпитализация, подтвержденная документально, если
          вышеперечисленные обстоятельства находятся вне контроля Сторон,
          препятствуют выполнению настоящего договора и возникли после
          заключения настоящего договора. Отсутствие времени у Заказчика по
          любым основаниям для прохождения обучающей программы, нахождение в
          отпуске, командировке, неоплата доступа к сети Интернет, поломка
          средства доступа к сети Интернет не являются обстоятельствами
          непреодолимой силы (форс-мажорными обстоятельствами).
        </p>
        <p>
          7.5. Совокупная ответственность Исполнителя по настоящему договору по
          любому иску или претензии в отношении договора или его исполнения
          ограничивается суммой платежа, уплаченного Исполнителю Заказчиком. При
          этом с Исполнителя может быть взыскан только реальный ущерб, но не
          упущенная выгода.
        </p>
        <p className="font-bold">8. Персональные данные и их использование</p>
        <p>
          8.1. Заказчик дает свое согласие Исполнителю на обработку своих
          персональных данных, предоставленных при регистрации в закрытом
          разделе Сайта, а именно:имя, фамилия; изображение; адрес электронной
          почты; контактный телефон (в случае предоставления); аккаунты в
          программах обмена сообщениями и социальных сетях.
        </p>
        <p>
          8.2. Обработка персональных данных означает запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных
          данных, не подпадающих под специальные категории, на обработку
          которых, согласно действующему законодательству Российской Федерации,
          требуется письменное согласие Заказчика.
        </p>
        <p>
          8.3. Обработка персональных данных производится в целях исполнения
          Исполнителем обязательств по настоящему договору, обеспечения
          Заказчика обратной связью при использовании Сайта, программ обучения,
          видео-курсов, а также в целях направления на указанный Заказчиком при
          регистрации адрес электронной почты информационных и рекламных
          сообщений.
        </p>
        <p>
          8.4. Обработка персональных данных Заказчика производится Исполнителем
          с использованием баз данных на территории Российской Федерации.
        </p>
        <p>
          8.5. Заказчик может в любое время отозвать согласие на обработку
          персональных данных, направив Исполнителю соответствующее уведомление
          на адрес: am-taro@gmail.com. При этом Заказчик понимает и признает,
          что отзыв на обработку персональных данных может потребовать удаления
          любой информации, касающейся участия Заказчика в обучающей программе,
          в том числе удаления учетной записи Заказчика в закрытых чатах и
          прекращения доступа к материалам приобретенного курса.
        </p>
        <p>
          8.6. Заказчик дает согласие на получение информационных рассылок и
          рекламных материалов от Исполнителя, либо от иных лиц по поручению
          Исполнителя, на адрес электронной почты и контактный телефон,
          указанные Заказчиком при регистрации на Сайте. Согласие на получение
          информационных рассылок и рекламных материалов может быть отозвано
          Заказчиком в любое время путем направления Исполнителю
          соответствующего уведомления на адрес: am-taro@gmail.com.
        </p>
        <p className="font-bold">9. Защита авторских прав</p>
        <p>
          9.1. Сайт содержит результаты интеллектуальной деятельности,
          принадлежащие Исполнителю, его аффилированным лицам и другим связанным
          сторонам, представителям, всем прочим лицам, действующим от имени
          Исполнителя.
        </p>
        <p>
          9.2. Используя Сайт/материалы курса, Заказчик признает и соглашается с
          тем, что все содержимое Сайта/материалов курса и структура содержимого
          Сайта/материалов курса защищены авторским правом, правом на товарный
          знак и другими правами на результаты интеллектуальной деятельности, и
          что указанные права являются действительными и охраняются во всех
          формах, на всех носителях и в отношении всех технологий, как
          существующих в настоящее время, так и разработанных или созданных
          впоследствии. Никакие права на любое содержимое Сайта/материалы курса,
          включая, помимо прочего, аудиовизуальные произведения, текстовые и
          графические материалы, программы для ЭВМ, товарные знаки не переходят
          к Заказчику в результате пользования Сайтом/материалами курса и
          заключения настоящего договора.
        </p>
        <p className="font-bold">10. Заключительные положения</p>
        <p>
          10.1. Настоящий договор вступает в силу с момента акцепта Заказчика в
          соответствии с п. 1.3. договора и действует до полного исполнения
          обязательств сторонами.
        </p>
        <p>
          10.2. Все споры и разногласия между сторонами разрешаются путем
          переговоров, либо в судебном порядке в соответствии с действующим
          законодательством РФ по месту регистрации Исполнителя.
        </p>
        <p>
          10.3. Бездействие со стороны Исполнителя в случае нарушения Заказчиком
          положений настоящего договора не лишает Исполнителя права предпринять
          позднее соответствующие действия в защиту своих интересов и защиту
          интеллектуальных прав на охраняемые в соответствии с законодательством
          материалы курсов.
        </p>
        <p>
          10.4. Признание судом какого-либо положения настоящего договора
          недействительным или не подлежащим принудительному исполнению не
          влечет недействительности иных его положений.
        </p>
        <p className="font-bold">11. Реквизиты Исполнителя</p>
        Аскерова Марина Федоровна (зарегистрирована как самозанятая) г.
        Екатеринбург, ИНН: 891104829890
      </div>

      <a href="/">
        <Button>Вернуться на главную </Button>
      </a>
      {/* logotype */}
      <div className="flex items-center justify-center py-10 ">
        <img
          src={logo}
          alt=""
          className="w-full h-full max-w-[60px] xl:max-w-[100px]"
        />
        <div className="flex flex-col md:hidden lg:flex">
          <span className="text-2xl xl:text-4xl font-bold text-accentColor2 font-Philosopher leading-none">
            Аскерова
          </span>
          <span className="-mt-2 text-2xl xl:text-4xl font-bold text-accentColor2 font-Philosopher leading-none">
            Марина
          </span>
        </div>
      </div>
    </section>
  );
};

export default Oferta;
